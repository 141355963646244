 

.rowOptionStyle {
  width: 90px;
}

.t-footer {
  color: black;
  background-color: #f8f9fb;
 
  font-size: 0.8rem;
  margin: 0px !important;
 
  border-top: solid 1px #dee2e6;;
}

.table-p{
  width: 1600px !important;
max-width: 100%;
}
@media only screen and (min-width: 400px) {
  .t-footer {
    color: black;
    font-size: 1rem;
  }
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}
.table   {
   
  border: 0;
}
.pageStyle {
  color: black;
  background-color: white;
  border: 1px solid #dee2e6 !important;
  font-size: 1rem !important;
  padding: 0.375rem 0.75rem !important;
}

.activePage {
  background-color: #cedeff;
  font-size: 1rem !important;
  padding: 0.375rem 0.75rem !important;
  border: 1px solid #dee2e6 !important;
}

.shrink {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  margin-left: -30px !important;
}
.shrinkTableButtons {
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  -ms-transform: scale(.9);
  transform: scale(.9);
  margin-left: -0px !important;
}
.shrinkButton {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  margin-left: -44px !important;
}

@media only screen and (min-width: 550px) {
 
  .shrink,
  .shrinkTableButtons,
  .shrinkButton {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    margin-left: 0px !important;
  }

  .activePage,
  .pageStyle {
    font-size: 1rem !important;
    padding: 0.375rem 0.75rem !important;
  }
}

button.btn.pageStyle.ellipses {
  cursor: default;
}

@media only screen and (min-width: 740px) {
  .width-check {
    width:100%;
  }
}

@media only screen and (min-width: 860px) {
  .width-check {
    width:100%;
  }
}

@media only screen and (min-width: 1170px) {
  .width-check {
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .width-check {
    width: 100%;
  }
}

@media only screen and (min-width: 1550px) {
  .width-check {
    width: 100%;
  }
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.head-2{
  width: 100% !important;
}

div#nav-tabContent{
  /* position: absolute; */
}

.plant-table-col {
  min-width: 120px; width: 120px;
}
@media screen and (max-width: 400px){
.plant-table-col {
  min-width: 80px; width: 80px
}
}
