.Filter-Item {
  display: inline;
  margin: .5rem 0 ;
  line-height: 14px;
}

@media screen and (max-width: 800px){
  .Filter-Options  {
 
    margin-top: .5rem;
  }
}
@media screen and (min-width: 800px){
  .Filter-Item {
 
    line-height: 20px;
  }
}
.Filter-Items {
  display: flex;
  flex-direction: column;
}
.Filters-side {
  display: flex;
  justify-content: space-around;
   flex-direction: column; 
}
.Filters-mobile {
  display: flex;
  justify-content: space-between;
   flex-direction: row; 
   flex-wrap: wrap;
}

.Filters-mobile .Filter-Item{
  width: 200px;
  /* font-size: .8rem; */

}
.Filters-mobile .Filter-Head{
 margin-bottom:.2rem;
}

.Filters-Section-mobile h3{
  margin-bottom: 0;

}

@media screen and (max-width:  450px){
  .Filters-mobile input[type=checkbox] {
    transform: scale(.8);
  }
  .Filters-mobile .Filter-Item{
    width: 150px;
    font-size: .8rem;
  
  }
   
  .Filters-Section-mobile h3{
    font-size: 1.4rem;
  }
  .modal-footer .btn, .editImage .btn{
    font-size: .8rem !important;
  }
  
}
@media screen and (max-width:  350px){
  .Filters-mobile input[type=checkbox] {
    transform: scale(.7);
  }
  .Filters-mobile .Filter-Item{
    width: 120px;
    font-size: .75rem;
  
  }
  
  .Filters-Section-mobile h3{
    font-size: 1.3rem;
  }
  .modal-footer .btn{
    font-size: .8rem !important;
  }
}
.Filter-Option {
  display: flex;
  align-items: center;
  margin:.6rem 0rem
}
.Filter-Option:first-of-type {
  
  margin:.3rem 0rem
}
.Filters-Section-side {
  margin-top: 50px;
  margin-right: 40px;

}

@media screen and (max-width: 800px){
  .Filters-Section-side {
    display: none;
  }
}


.Filter-Option input {
  margin-right: 5px;
}

.Filter-Option label {
  margin-bottom: 0;
}

button.btn.btn-primary.default-button.filters-button {
  margin-left: .5rem;
}

@media screen and (min-width: 800px){
  button.btn.btn-primary.default-button.filters-button {
    display: none;
  }
}
