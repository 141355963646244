.over {
  overflow: scroll;
}

.main-Container {
  display: flex;
  flex-direction: column
}

.plant-area {
  display: flex;
  /* flex-direction: column */
  width:100%;
}
table 
{
    table-layout:fixed;
    /* width:100%; */
    word-break: break-word;
    border: 0px;
}
.plant-tables {
  display: flex;width:100%;
   flex-direction: column;
   min-width: 0;
   border: solid 1px #dee2e6;
}
.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
  width: 12px;
}

.table-responsive::-webkit-scrollbar:horizontal {
  height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

@media screen and (max-width: 600px) {
  h1.display-4 {
    font-size: 2.5rem;
  }
}

body {
  background-color: #f8f9fb;
}


@media only screen and (min-width: 400px) and (max-width: 800px) {
  p {
    font-size: 1rem;
  }
  .search-bar{
margin-top: 2rem;
  }
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0;
}
.loader-button {
  width: 106.1px;
}

.empty-form-group {
  display: none;
}

.editImage {
  background-color: white;
  padding: 10px;
  max-width: 400px;
  border: solid 1px;
  border-color: gray;
  font-size: 0.8rem;
  border-radius: 5px;
  margin-top: 9px;
}
.imageInputBtn {
  margin: 2px 0px 1px 3px;
  font-size: 0.8rem;
}
.editImageWarning {
  font-size: 0.8rem;
  margin-left: 4px;
}

.list-group-item:focus, .list-group-item:hover {
  z-index: 0 !important;
  text-decoration: none;
}

.profile-pic-div {
  max-width: 120px;
  max-height: 120px;
  overflow: hidden;
}
.pic-img {
  max-width: 50px;
  max-height: 50px;
  object-fit: scale-down;
  border: solid 1px black;
margin-bottom: .3rem;
 
  border-radius: 5px;
}
.imageInput {
  margin: 2px 0px 10px 3px;
  max-width: 244px;
  font-size: 0.8rem;
  display: block;
}

@media screen and (min-width: 397px) {
  .imageInput {
    margin: 2px 0px 10px 3px;
    max-width: 350px;
  }
}

@media screen and (min-width: 500px) {
  .editImage,
  .editImageWarning,
  .imageInput,
  .imageInputBtn {
    font-size: 1rem;
  }
}
.Nav {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 1rem 0;
  flex-wrap: wrap;
  min-height: 80px;
}

 
.main-Container{
  padding: 0rem 2rem !important;
}

.auth-links{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 82px;
}

@media screen and (max-width: 800px){
  .Nav :nth-child(2) {
    order: 3;  
    
 }
 .Nav {
 
  /* min-height: 140px; */
}
}

@media screen and (min-width: 800px){
  .Nav {
    flex-direction: row;

    margin: 2rem 0 1rem 0;
  }
}

.list-group{
  flex-direction: row !important;
}
.list-group-item {
  margin-bottom: 0px;
}
.tab-row {
  align-content: flex-start;
  min-width: 0;
}

.table-p{
  /* text-align: center; */
  margin: 0px;
  padding-left: 10px;
  padding: .5rem 1rem !important;
}

/* .list-group-item {
  border-bottom: 0px;
}
.list-group-item:first-child {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

a#list-home-link {
  border: 1px solid #dee2e6;
} */

a#list-home-link, a#list-profile-link {
  border: 1px solid #dee2e6;
  width: 250px;
  border-radius: 0px;
  border-bottom: 0px;
  min-width: 80px;
  text-align: center;
}

a#list-home-link {
  border-right: 0px;
  border-top-left-radius: .25rem;
}

a#list-profile-link {
  border-left: 0px;
  border-top-right-radius: .25rem;
}
.list-group-item-action.active {
  border-top: 1px solid #007bff !important;
  border-left: 1px solid #007bff !important;
  border-right: 1px solid #007bff !important;
}
@media screen and (max-width: 400px){
  body {
    font-size: 1rem;
    line-height: 1rem;
    
}
p {
  font-size: 0.8rem;
}

.btn {
  font-size: 1rem;
  /* -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8); */
}


.list-group-item {
  padding:.7rem 1rem;
  text-align: center;
  font-size: 1rem;
}
.search-bar{
  margin-top: 1.3rem;
  margin-bottom: 1rem;
}
}

@media screen and (max-width: 430px){
  h1 {
    font-size: 1.5rem;
  }
  a {
    font-size: .9rem;
  
  };
/* 
  .filters-button, .search-bar, button.btn.btn-primary.btn-primary.default-button.filters-button {
    font-size: .8rem !important;
  } */
}


@media screen and (min-width: 400px) and (max-width: 800px){
  
 .plant-area {
   margin-top: 1rem;
 }
}

  
h1 {
  margin-bottom: .3rem;
}
 
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  color:#007bff;
}

.logo-link:hover {
  text-decoration: none;
  color: #007bff;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px !important;
}