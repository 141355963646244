.colHead {
  font-weight: 800;
}

.table-primary-1 {
  border: 1px solid #dee2e6;

  margin-top: 10px;
  /* max-width: 100%; */
}
.input-primary {
  border: 1px solid #dee2e6;
}
.dontShowFilter {
  display: none !important;
}
.default-button {
  margin: 0px 0px 8px 10px;
}

.hideButton {
  display: none !important;
  font-size: 30px;
}

input.input-primary {
  width: 300px;
  margin: 0px 0px 10px 0px;
}

button.btn.btn-primary.default-button {
  margin: 0px 10px 0px 0px;
}

@media only screen and (min-width: 350px) {
  .selectClass {
    width: 313px;
  }
}

.filter-loader-button,
.filter-button {
  width: 133px;
}
 
.search-bar{
  display: flex;
  /* flex-grow: 1;
  flex-basis: 100%; */
  margin: 0rem 0rem;
}

.search-box {
  margin-right: 10px;
  flex-grow: 1;
  z-index: 3;
  
}

.search-bar button.btn.btn-primary.default-button{
  margin-right: 0
}

@media screen and (max-width: 800px){
  .search-bar{
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    /* margin: 0rem 0rem; */
  }
}

@media screen and (min-width: 800px){
   
.search-bar{
  display: flex;
  flex-grow: 1;
  margin: 0rem 3rem;
}
}

@media screen and (min-width: 1200px){
   
  .search-bar{
    display: flex;
    flex-grow: 1;
    margin: 0rem 7rem;
  }
  }

  @media screen and (min-width: 1600px){
   
    .search-bar{
      display: flex;
      flex-grow: 1;
      margin: 0rem 11rem;
    }
    }

    